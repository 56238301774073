import React from 'react'
import SvgIcon, { type SvgIconProps } from '~/components/SvgIcon'

const Instagram = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path d="M8 4.40625C9.96875 4.40625 11.5938 6.03125 11.5938 8C11.5938 10 9.96875 11.5938 8 11.5938C6 11.5938 4.40625 10 4.40625 8C4.40625 6.03125 6 4.40625 8 4.40625ZM8 10.3438C9.28125 10.3438 10.3125 9.3125 10.3125 8C10.3125 6.71875 9.28125 5.6875 8 5.6875C6.6875 5.6875 5.65625 6.71875 5.65625 8C5.65625 9.3125 6.71875 10.3438 8 10.3438ZM12.5625 4.28125C12.5625 3.8125 12.1875 3.4375 11.7188 3.4375C11.25 3.4375 10.875 3.8125 10.875 4.28125C10.875 4.75 11.25 5.125 11.7188 5.125C12.1875 5.125 12.5625 4.75 12.5625 4.28125ZM14.9375 5.125C15 6.28125 15 9.75 14.9375 10.9062C14.875 12.0312 14.625 13 13.8125 13.8438C13 14.6562 12 14.9062 10.875 14.9688C9.71875 15.0312 6.25 15.0312 5.09375 14.9688C3.96875 14.9062 3 14.6562 2.15625 13.8438C1.34375 13 1.09375 12.0312 1.03125 10.9062C0.96875 9.75 0.96875 6.28125 1.03125 5.125C1.09375 4 1.34375 3 2.15625 2.1875C3 1.375 3.96875 1.125 5.09375 1.0625C6.25 1 9.71875 1 10.875 1.0625C12 1.125 13 1.375 13.8125 2.1875C14.625 3 14.875 4 14.9375 5.125ZM13.4375 12.125C13.8125 11.2188 13.7188 9.03125 13.7188 8C13.7188 7 13.8125 4.8125 13.4375 3.875C13.1875 3.28125 12.7188 2.78125 12.125 2.5625C11.1875 2.1875 9 2.28125 8 2.28125C6.96875 2.28125 4.78125 2.1875 3.875 2.5625C3.25 2.8125 2.78125 3.28125 2.53125 3.875C2.15625 4.8125 2.25 7 2.25 8C2.25 9.03125 2.15625 11.2188 2.53125 12.125C2.78125 12.75 3.25 13.2188 3.875 13.4688C4.78125 13.8438 6.96875 13.75 8 13.75C9 13.75 11.1875 13.8438 12.125 13.4688C12.7188 13.2188 13.2188 12.75 13.4375 12.125Z" />
    </SvgIcon>
  )
}

export default Instagram
