import React from 'react'
import { useTranslation } from 'react-i18next'
import { motion, useWillChange } from 'framer-motion'
import type { TFunction } from 'i18next'
import Container from '~/components/Container'
import LinkedinIcon from '~/components/icons/Linkedin'
import Plus from '~/components/icons/Plus'
import Link from '~/components/Link'
import MenuFooter from '~/components/Menu/MenuFooter'
import MenuHeader from '~/components/Menu/MenuHeader'
import { ROUTES } from '~/constants/routes'
import { INSTAGRAM_URL, LINKEDIN_URL } from '~/constants/social'
import { cn } from '~/utils/cn'
import { trackEvent } from '~/utils/tracking'
import { useToggle } from '@zoi/react-hooks'
import InstagramIcon from '../icons/Instagram'

type SimpleLinkItem = {
  title: string
  items?: never
  to: ValuesOf<typeof ROUTES>
}

type FilledLinkItem = {
  title: string
  items: SimpleLinkItem[]
  to?: never
}

type LinkItem = FilledLinkItem | SimpleLinkItem

const getLinkItems = (t: TFunction): LinkItem[] => {
  return [
    {
      title: t('common:theCheckUp'),
      to: ROUTES.checkup
    },
    {
      title: t('common:theFollowUp'),
      to: ROUTES.followup
    },
    {
      title: t('common:book'),
      items: [
        {
          title: t('common:forMe'),
          to: ROUTES.subscription_booking
        },
        {
          title: t('common:throughCompany'),
          to: ROUTES.bookingCompanies_form
        }
      ]
    },
    {
      title: t('common:manifesto'),
      to: ROUTES.manifesto
    },
    {
      title: t('common:companies'),
      to: ROUTES.companies
    }
  ]
}

const getSubLinkItems = (t: TFunction): SimpleLinkItem[] => {
  return [
    {
      title: t('common:theTeam'),
      to: ROUTES.team
    },
    {
      title: t('common:jobs'),
      to: ROUTES.jobs
    },
    {
      title: t('common:research'),
      to: ROUTES.research
    },
    {
      title: t('common:q&a'),
      to: ROUTES.faq
    }
  ]
}

type CursorProperties = {
  y: number
  height: number
  index: number
  typeList: 'primary' | 'secondary'
}

export type MenuProps = {
  onClose: () => void
}

const Menu = ({ onClose }: MenuProps) => {
  const { t } = useTranslation()
  const [isAccordionOpened, toggleAccordionOpened] = useToggle(false)

  const willChange = useWillChange()
  const [cursorProperties, setCursorProperties] =
    React.useState<CursorProperties>({
      y: 0,
      index: -1,
      typeList: 'primary',
      height: 0
    })

  const syncElementWithCursor = ({
    index,
    typeList
  }: Pick<CursorProperties, 'typeList' | 'index'>) => {
    return (
      event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>
    ) => {
      const elementHeight = event.currentTarget.clientHeight
      const elementY = event.currentTarget.offsetTop
      setCursorProperties({
        index,
        typeList,
        y: elementY + 5,
        height: elementHeight - 10
      })
    }
  }

  const isHoveringPrimaryList =
    cursorProperties.typeList === 'primary' && cursorProperties.index !== -1

  return (
    <motion.div
      className="fixed inset-0 z-50 h-dvh w-full overflow-hidden bg-black text-white"
      data-modal-opened
      initial={{ height: 0 }}
      animate={{ height: '100dvh' }}
      exit={{ height: 0 }}
      style={{ transitionTimingFunction: 'cubic-bezier(.315,.38,.035,1)' }}
      transition={{ duration: 0.4, ease: 'easeInOut' }}
    >
      <div className="relative flex h-full w-full flex-col overflow-y-auto">
        <div className="sticky top-0 z-10 bg-black">
          <MenuHeader onClose={onClose} />
        </div>
        <Container className="flex flex-1 justify-center gap-5 max-lg:px-0">
          <div className="flex h-full w-full flex-col justify-between gap-y-4 pb-3 lg:pb-6 lg:pl-16">
            <div className="relative flex flex-col gap-y-4 lg:gap-y-8">
              <div className="-left-14 top-0 hidden h-full lg:absolute lg:block">
                <motion.div
                  aria-hidden="true"
                  className="w-[1px] bg-white"
                  initial={false}
                  style={{ willChange }}
                  animate={{
                    height: cursorProperties.height,
                    y: cursorProperties.y
                  }}
                  transition={{ ease: 'easeInOut', duration: 0.3 }}
                />
                <div
                  aria-hidden="true"
                  className="absolute top-0 h-full w-[1px] bg-white/5"
                />
              </div>
              <ul className="flex flex-col">
                {getLinkItems(t).map((item, index) => {
                  const handleSyncItem = syncElementWithCursor({
                    index,
                    typeList: 'primary'
                  })
                  const isActive =
                    index === cursorProperties.index && isHoveringPrimaryList

                  const className = cn(
                    'block lg:w-[45%] py-1 font-zoi text-6xl !font-extralight uppercase not-italic text-white transition-colors container-gutters-x aria-selected:text-white max-lg:py-4 max-lg:text-white max-md:text-brand-h4 lg:px-0 text-left',
                    {
                      'text-white/25': isHoveringPrimaryList,
                      'text-white': isActive
                    }
                  )

                  return (
                    <li
                      key={item.title}
                      onMouseOver={handleSyncItem}
                      onFocus={handleSyncItem}
                      className="group flex flex-col gap-x-10 border-b border-white/10 lg:flex-row lg:items-center lg:border-b-0"
                    >
                      {item.to ? (
                        <Link
                          to={item.to}
                          aria-selected={isActive}
                          className={className}
                          onClick={onClose}
                        >
                          {item.title}
                        </Link>
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={toggleAccordionOpened}
                            data-is-opened={isAccordionOpened}
                            className={cn(
                              className,
                              'group flex items-center transition-colors max-lg:data-[is-opened=true]:text-white/25'
                            )}
                          >
                            <div className="flex-1">{item.title}</div>
                            <div className="shrink-0 lg:hidden">
                              <div className="flex items-center justify-center transition-transform group-data-[is-opened=true]:rotate-45">
                                <Plus width={20} height={20} />
                              </div>
                            </div>
                          </button>
                          <div
                            className={cn(
                              'relative hidden flex-1 max-lg:mb-4 max-lg:container-gutters-x lg:h-full',
                              {
                                'lg:block': isActive,
                                'max-lg:block': isAccordionOpened
                              }
                            )}
                          >
                            <ul className="left-0 top-[40%] flex flex-col lg:absolute lg:gap-y-2">
                              {item.items.map((subItem) => {
                                return (
                                  <li key={subItem.title}>
                                    <Link
                                      to={subItem.to}
                                      className="block py-2 text-white hover:text-white lg:py-1 lg:text-white/80"
                                      onClick={onClose}
                                    >
                                      {subItem.title}
                                    </Link>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </>
                      )}
                    </li>
                  )
                })}
              </ul>
              <ul>
                {getSubLinkItems(t).map((item, index) => {
                  const handleSyncItem = syncElementWithCursor({
                    index,
                    typeList: 'secondary'
                  })
                  const isActive =
                    index === cursorProperties.index &&
                    cursorProperties.typeList === 'secondary'

                  return (
                    <li
                      key={item.title}
                      onMouseOver={handleSyncItem}
                      onFocus={handleSyncItem}
                    >
                      <Link
                        prefetch="none"
                        to={item.to}
                        aria-selected={isActive}
                        className="block py-3 text-body1 text-white aria-selected:text-white max-lg:container-gutters-x"
                        onClick={onClose}
                      >
                        {item.title}
                      </Link>
                    </li>
                  )
                })}
                <li className="mt-4 flex gap-3 max-lg:container-gutters-x">
                  <a
                    href={INSTAGRAM_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="flex aspect-square items-center justify-center border-white/15 transition-colors hover:border-white max-lg:h-10 max-lg:w-10 max-lg:rounded-full max-lg:border"
                    onClick={() => {
                      trackEvent('MENU_INSTAGRAM_CLICK')
                    }}
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    href={LINKEDIN_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="flex aspect-square items-center justify-center border-white/15 transition-colors hover:border-white max-lg:h-10 max-lg:w-10 max-lg:rounded-full max-lg:border"
                    onClick={() => {
                      trackEvent('MENU_LINKEDIN_CLICK')
                    }}
                  >
                    <LinkedinIcon />
                  </a>
                </li>
              </ul>
            </div>
            <MenuFooter onClose={onClose} />
          </div>
        </Container>
      </div>
    </motion.div>
  )
}

export default Menu
