import React from 'react'
import SvgIcon, { type SvgIconProps } from '~/components/SvgIcon'

const ChevronBottom = (props: SvgIconProps) => {
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
      <path d="M6.5 9L11.7969 3.70313L11 2.92969L10.6016 3.30469L6.5 7.42969L2.39844 3.30469L2 2.90625L1.20313 3.70313L6.5 9Z" />
    </SvgIcon>
  )
}

export default ChevronBottom
