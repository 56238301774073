import React from 'react'
import SvgIcon, { type SvgIconProps } from '~/components/SvgIcon'

const SimpleLogo = (props: SvgIconProps) => {
  return (
    <SvgIcon width="30" height="29" viewBox="0 0 30 29" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.6875 0.325317L29.1794 0.325317L29.1794 4.99281L0.6875 4.99281L0.6875 0.325317ZM0.6875 7.92467L29.1794 7.92467L29.1794 12.5922L0.6875 12.5922L0.6875 7.92467ZM29.1794 18.2443L0.6875 22.5115L0.6875 17.846L29.1794 13.5768L29.1794 18.2443ZM0.6875 23.4823L29.1794 23.4823L29.1794 28.1498L0.6875 28.1498L0.6875 23.4823Z"
      />
    </SvgIcon>
  )
}

export default SimpleLogo
