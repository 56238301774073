import { useLocation } from '@remix-run/react'
import { useLocale } from '~/hooks/useLocale'
import { SUPPORTED_LOCALES } from '~/i18n/config'
import { setLocalePrefixToLinkTo } from '~/utils/i18n-link'

export function useLanguageSwitcher() {
  const locale = useLocale()
  const location = useLocation()

  return SUPPORTED_LOCALES.map((supportedLocale) => {
    const toPrefixed = setLocalePrefixToLinkTo(
      {
        pathname: location.pathname,
        search: location.search
      },
      supportedLocale
    )

    return {
      locale: supportedLocale,
      isActive: locale === supportedLocale,
      to: toPrefixed
    }
  })
}
