import React from 'react'
import SvgIcon, { type SvgIconProps } from '~/components/SvgIcon'

const Linkedin = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path d="M4.125 14H1.21875V4.65625H4.125V14ZM2.65625 3.40625C1.75 3.40625 1 2.625 1 1.6875C1 1.09375 1.3125 0.53125 1.8125 0.25C2.34375 -0.0625 3 -0.0625 3.5 0.25C4.03125 0.53125 4.34375 1.09375 4.34375 1.6875C4.34375 2.625 3.59375 3.40625 2.65625 3.40625ZM12.0938 14V9.46875C12.0938 8.375 12.0625 7 10.5625 7C9.0625 7 8.84375 8.15625 8.84375 9.375V14H5.9375V4.65625H8.71875V5.9375H8.75C9.15625 5.21875 10.0938 4.4375 11.5 4.4375C14.4375 4.4375 15 6.375 15 8.875V14H12.0938Z" />
    </SvgIcon>
  )
}

export default Linkedin
